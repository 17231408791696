<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="税盘编号">
                <a-input v-model="queryParam.tax_tray_no" placeholder="根据税盘编号查询" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="开票终端编码">
                <a-input v-model="queryParam.invoice_terminal_code" placeholder="根据开票终端编码查询" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="税盘名称">
                <a-input v-model="queryParam.tax_tray_name" placeholder="根据税盘名称查询" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="查询参数">
                <a-input v-model="queryParam.like_param" placeholder="模糊搜索其余信息" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="部门">
                <a-select
                  v-model="queryParam.department"
                  allowClear
                  placeholder="请选择部门"
                >
                  <a-select-option v-for="op in this.GLOBAL.departmentMaps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="query" icon="search" @click="queryList">查询</a-button>
        <a-button type="primary" icon="plus" @click="handleCreate">新增</a-button>
        <a-button type="danger" icon="delete" @click="handleDelete" :disabled="!selectedRowKeys.length">删除</a-button>
      </div>

      <a-table
        size="small"
        row-key="id"
        bordered
        :scroll="{ x: 'max-content' }"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="handleChangeTable"
        :row-selection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
      >
        <template slot="action" slot-scope="text, record">
          <a @click="handleUpdate(record)">编辑</a>
        </template>
      </a-table>

      <a-modal
        :visible="visible"
        :confirmLoading="loading"
        :width="800"
        :maskClosable="false"
        :title="title"
        @ok="handleSubmit"
        @cancel="handleCancel"
      >
        <a-form-model
          layout="vertical"
          ref="form"
          :model="form"
          :rules="rules"
        >
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-model-item label="开票终端编码">
                <a-input v-model.trim="form.invoice_terminal_code" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="税盘编号">
                <a-input v-model.trim="form.tax_tray_no" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="税盘名称" prop="tax_tray_name">
                <a-input v-model.trim="form.tax_tray_name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="销货单位名称" prop="sale_company_name">
                <a-input v-model.trim="form.sale_company_name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="纳税人识别号" prop="sale_company_tax_no">
                <a-input v-model.trim="form.sale_company_tax_no" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="地址" prop="sale_company_address">
                <a-input v-model.trim="form.sale_company_address" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="电话" prop="sale_company_mobile">
                <a-input v-model.trim="form.sale_company_mobile" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="开户行" prop="sale_company_bank">
                <a-input v-model.trim="form.sale_company_bank" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="账号" prop="sale_company_account">
                <a-input v-model.trim="form.sale_company_account" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="收款人" prop="payee">
                <a-input v-model.trim="form.payee" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="复核人" prop="checker">
                <a-input v-model.trim="form.checker" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="开票人" prop="opener">
                <a-input v-model.trim="form.opener" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="税盘关联部门" prop="department_ids">
                <a-select
                  v-model="form.department_ids"
                  allowClear
                  placeholder="请选择部门"
                  mode="multiple"
                >
                  <a-select-option v-for="op in this.GLOBAL.departmentMaps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import {
  pageInvoiceTaxTray,
  saveOrUpdateInvoiceTaxTray,
  deleteTaxTray
} from '@/api/fms'

export default {
  name: 'FmsInvoiceTaxTray',
  data() {
    return {
      queryParam: {},
      selectedRowKeys: [],
      selectedRows: [],
      columns: [
        {
          title: '开票终端编码',
          dataIndex: 'invoice_terminal_code',
          align: 'center'
        },
        {
          title: '税盘编号',
          dataIndex: 'tax_tray_no'
        },
        {
          title: '税盘名称',
          dataIndex: 'tax_tray_name'
        },
        {
          title: '部门列表',
          dataIndex: 'department_str',
          width: 300
        },
        {
          title: '销货单位名称',
          dataIndex: 'sale_company_name'
        },
        {
          title: '纳税人识别号',
          dataIndex: 'sale_company_tax_no'
        },
        {
          title: '地址',
          dataIndex: 'sale_company_address'
        },
        {
          title: '电话',
          dataIndex: 'sale_company_mobile'
        },
        {
          title: '开户行',
          dataIndex: 'sale_company_bank'
        },
        {
          title: '账号',
          dataIndex: 'sale_company_account'
        },
        {
          title: '收款人',
          dataIndex: 'payee'
        },
        {
          title: '复核人',
          dataIndex: 'checker'
        },
        {
          title: '开票人',
          dataIndex: 'opener'
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '50'],
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      visible: false,
      loading: false,
      title: null,
      form: {
        invoice_terminal_code: null,
        tax_tray_no: null,
        tax_tray_name: null,
        sale_company_name: null,
        sale_company_tax_no: null,
        sale_company_address: null,
        sale_company_mobile: null,
        sale_company_bank: null,
        sale_company_account: null,
        payee: null,
        checker: null,
        opener: null,
        department_ids: []
      },
      rules: {
        tax_tray_no: [{ required: true, message: '请输入税盘编号', trigger: 'blur' }],
        tax_tray_name: [{ required: true, message: '请输入税盘名称', trigger: 'blur' }],
        sale_company_name: [{ required: true, message: '请输入销货单位名称', trigger: 'blur' }],
        sale_company_tax_no: [{ required: true, message: '请输入纳税人识别号', trigger: 'blur' }],
        department_ids: [{ required: true, message: '请选择税盘关联哪些部门', trigger: 'change' }]
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      pageInvoiceTaxTray(this.queryParam).then(res => {
        this.data = res.list
        this.pagination.total = res.total
      })
    },
    queryList() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.queryParam['page'] = 1
      this.queryParam['page_size'] = 10
      this.loadData()
    },
    handleChangeTable(page) {
      this.pagination.current = page.current
      this.pagination.pageSize = page.pageSize
      this.queryParam['page'] = page.current
      this.queryParam['page_size'] = page.pageSize
      this.loadData()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleCreate() {
      this.visible = true
      this.title = '新增税盘信息'
    },
    handleUpdate(record) {
      this.visible = true
      this.title = '修改税盘信息'
      this.form = Object.assign({}, record)
    },
    handleSubmit() {
      this.loading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          saveOrUpdateInvoiceTaxTray(this.form).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            })
            this.handleCancel()
            this.loadData()
          })
        } else {
          this.loading = false
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.form = {
        invoice_terminal_code: null,
        tax_tray_no: null,
        tax_tray_name: null,
        sale_company_name: null,
        sale_company_tax_no: null,
        sale_company_address: null,
        sale_company_mobile: null,
        sale_company_bank: null,
        sale_company_account: null,
        payee: null,
        checker: null,
        opener: null,
        department_ids: []
      }
    },
    handleDelete() {
      this.$confirm({
        title: '提示',
        content: '确认要删除吗?',
        onOk: () => {
          const idList = this.selectedRows.map(item => item.id)
          deleteTaxTray({ ids: idList }).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功'
            })
            this.loadData()
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
